import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['page']

	connect(){
		var tab = this.pageTarget.dataset.tab;
		document.querySelectorAll(`[data-link]`).forEach(function(link){
			link.classList.remove('active');

		});
		document.querySelector(`[data-link="${tab}"]`).classList.toggle("active");
	}

	// connect() {
  //   this.element.textContent = "Hello World!"
  // }
}
