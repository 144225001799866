import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["select"]

	connect(){
		console.info("loaded")
		$(this.selectTarget).daterangepicker({
			ranges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		})
		$(this.selectTarget).on('apply.daterangepicker', function(e,picker){
			document.getElementById('filter_form').submit();
		})
	}

	// connect() {
  //   this.element.textContent = "Hello World!"
  // }
}
