import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["paid"]

	connect(){
		console.info('load invoice filter');
		$(this.paidTarget).on('change',function(){
			// document.getElementById('filter_form').submit();
		})
		// $(this.billingTarget).select2({theme: "bootstrap-5", placeholder: "Select User", allowClear: true})		
		// $(this.stateTarget).select2({theme: "bootstrap-5", placeholder: "Select State", allowClear: true})		
	}

	// connect() {
  //   this.element.textContent = "Hello World!"
  // }
}
