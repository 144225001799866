import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['billing','state']

	connect(){
		console.info("select2 loaded")
		$(this.billingTarget).select2({theme: "bootstrap-5", placeholder: "Select User", allowClear: true})		
		$(this.stateTarget).select2({theme: "bootstrap-5", placeholder: "Select State", allowClear: true})		
		// $(this.selectTarget).on('apply.daterangepicker', function(e,picker){
			// document.getElementById('filter_form').submit();
		// })
	}

	// connect() {
  //   this.element.textContent = "Hello World!"
  // }
}
