// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("channels")

import '@popperjs/core'
import 'bootstrap'

import jquery from 'jquery'
window.$ = jquery
window.jQuery = jquery

import moment from 'moment'
window.moment = moment
// import 'jquery/src/jquery'
import datepicker from "bootstrap-daterangepicker/daterangepicker.js"
import "bootstrap-daterangepicker/daterangepicker.css"
import select2 from "select2/dist/js/select2.js"
import "select2/dist/css/select2.min.css"
import "select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css"
window.datepicker = datepicker


// (function(){
// 	document.addEventListener('turbo:after-fetch-request',function(){
// 		$("input.daterange").daterangepicker({
// 			ranges: {
// 				'Today': [moment(), moment()],
// 				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
// 				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
// 				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
// 				'This Month': [moment().startOf('month'), moment().endOf('month')],
// 				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
// 			}
// 		})
// 	})
// })();

// import moment from 'moment'
//
// require("dashkit/theme.bundle")
// require("dashkit/vendor.bundle")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

window.confirmAction = function(e,message){
	var _a = confirm(message);
	if(_a){
		console.info(e);
	}else
		e.stopPropagation();
		e.preventDefault();
}

